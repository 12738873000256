import { axios } from '@/utils/request'
const basic = '/manage/template'
const api = {
  // 分页查询模板
  findTemplate: basic + '/findTemplate',
  // 分页查询模板
  findAllTemplate: basic + '/findAllTemplate',
  // 添加模板
  addTemplate: basic + '/addTemplate',
  // 删除模板
  delTemplate: basic + '/delTemplate',
  // 模板详情
  templateDetails: basic + '/templateDetails'
}
export function addTemplate (parameter) {
  return axios({
    url: api.addTemplate,
    method: 'post',
    data: parameter
  })
}
export function findTemplate (parameter) {
  return axios({
    url: api.findTemplate,
    method: 'post',
    params: parameter
  })
}
export function templateDetails (parameter) {
  return axios({
    url: api.templateDetails,
    method: 'post',
    params: parameter
  })
}
export function delTemplate (parameter) {
  return axios({
    url: api.delTemplate,
    method: 'post',
    params: parameter
  })
}
export function fieldAdd (parameter) {
  return axios({
    url: '/manage/field/add',
    method: 'post',
    params: parameter
  })
}
