<template>
  <keep-alive include="ListTemplateView">
    <component :is="component" @cut="cut" :id="id" :type="type"></component>
  </keep-alive>
</template>

<script>
import ListTemplateView from '@/views/template/template/children/ListTemplateView'
import EditTemplateView from '@/views/template/template/children/EditTemplateView'
export default {
  name: 'TemplateView',
  components: {
    ListTemplateView,
    EditTemplateView
  },
  data () {
    return {
      component: 'ListTemplateView',
      id: null,
      type: ''
    }
  },
  methods: {
    cut (name, type, data) {
      this.id = data
      this.type = type
      this.component = name
    }
  }
}
</script>

<style scoped>

</style>
