<template>
  <a-modal
    :title="editModalTitle"
    :maskClosable="false"
    v-model="visible"
    :confirmLoading="confirmLoading"
    @ok="editHandleOk"
    @cancel="editHandleCancel"
    width="550px"
    class="edit-permission-component"
  >
    <a-form :form="form" @submit="editHandleOk">
      <a-form-item label="类别" :label-col="{ span: 6 }" :wrapper-col="{ span: 12 }">
        <a-input :maxLength="300" v-decorator="nameValidate"/>
      </a-form-item>
      <a-form-item label="状态" :label-col="{ span: 6 }" :wrapper-col="{ span: 12 }">
        <a-select v-decorator="typeValidate" :disabled="disabledNameInput">
          <a-select-option value="SUCCESS">成功</a-select-option>
          <a-select-option value="FAIL">失败</a-select-option>
        </a-select>
      </a-form-item>
    </a-form>
  </a-modal>
</template>

<script>
import { addExplicit } from '@/api/template/explicit'
export default {
  name: 'TypeTemplateComponent',
  props: {
    showEditModal: Boolean,
    editData: {
      type: Object,
      default: function () {
        return {}
      }
    },
    parentId: {
      type: Number,
      default: 0
    }
  },
  watch: {
    showEditModal: function (newVal) {
      this.visible = newVal
      this.createForm()
    },
    editData: {
      handler (newVal) {
        this.createForm()
        this.newEditData = newVal
        if (Object.keys(this.editData).length === 0) {
          this.editModalTitle = '新增状态内容'
          this.disabledNameInput = false
        } else {
          this.editModalTitle = '编辑状态内容'
          this.disabledNameInput = true
        }
        this.nameValidate[1].initialValue = this.newEditData.name
        this.typeValidate[1].initialValue = this.newEditData.type
      },
      deep: true
    }
  },
  data () {
    return {
      disabledNameInput: false,
      topPermissionsList: [],
      iconType: 'edit',
      confirmLoading: false,
      visible: false,
      nameValidate: ['name', { rules: [{ required: true, message: '类别不能为空！' }] }],
      typeValidate: ['type', { rules: [{ required: true, message: '状态不能为空！' }] }],
      form: null,
      newEditData: {},
      editModalTitle: '新增权限'
    }
  },
  beforeCreate () {
    if (this.form === null) {
      this.form = this.$form.createForm(this)
    }
  },
  created () {
    this.createForm()
  },
  methods: {
    createForm () {
      this.form = this.$form.createForm(this)
    },
    editHandleCancel () {
      this.form.resetFields()
      this.$emit('cancel')
    },
    editPermissionSubmit (values) {
      if (this.newEditData && this.newEditData.id) {
        values.id = this.newEditData.id
      }
      return addExplicit(values)
    },
    editHandleOk () {
      this.form.validateFields((err, values) => {
        if (err) {
          return
        }
        const data = { parentId: this.parentId, ...values, id: this.editData.id }
        this.$emit('ok', data)
      })
    }
  }
}
</script>

<style scoped>

</style>
