<template>
  <a-card :bordered="false">
    <p class="title">{{ type }}营销状态模板</p>
    <a-form :form="form" @submit="editHandleOk">
      <a-form-item label="模板名称" :label-col="{ span: 6 }" :wrapper-col="{ span: 12 }">
        <a-input :maxLength="300" :disabled="type==='查看'" v-decorator="nameValidate" placeholder="请输入模板名称"/>
      </a-form-item>
      <a-form-item label="模板描述" :label-col="{ span: 6 }" :wrapper-col="{ span: 12 }">
        <a-textarea
          :disabled="type==='查看'"
          v-decorator="remarkValidate"
          placeholder="请输入模板描述"
          :autoSize="{ minRows: 3, maxRows: 5 }"
        />
      </a-form-item>
      <a-form-item label="模板内容" :label-col="{ span: 6 }" :wrapper-col="{ span: 12 }">
        <div>
          <a-button type="text" size="small" v-if="type!=='查看'" @click="addClick">新增</a-button>
        </div>
        <a-table
          v-if="tableData.length!==0"
          :defaultExpandAllRows="true"
          :pagination="false"
          :columns="columns"
          :dataSource="tableData"
          rowKey="id"
          childrenColumnName="childFiled">
          <span slot="type" slot-scope="type">
            {{ type | type }}
          </span>
          <span slot="action" slot-scope="text" v-if="type!=='查看'">
            <a-button type="primary" size="small" @click="addChildClick(text)" v-if="text.parentId===0">创建</a-button>
            <a-divider type="vertical" />
            <a-button type="primary" size="small" @click="editClick(text)">修改</a-button>
            <a-divider type="vertical" />
            <a-popconfirm title="确认删除？" okText="是" cancelText="否" @confirm="delOk(text)">
              <a-button type="danger" size="small" >删除</a-button>
            </a-popconfirm>
          </span>
        </a-table>
      </a-form-item>
    </a-form>
    <div class="footer">
      <a-button type="primary" @click="editHandleOk()" v-if="type!=='查看'">确定</a-button>
      <a-button type="primary" @click="back()">返回</a-button>
    </div>
    <type-template-component :showEditModal="showEditModal" :editData="editData" :parentId="parentId" @cancel="cancelHandle" @ok="editHandle"></type-template-component>
  </a-card>
</template>

<script>
import { addTemplate, templateDetails, fieldAdd } from '@/api/template/template'
import TypeTemplateComponent from '@/views/template/template/children/TypeTemplateComponent'
export default {
  name: 'EditTemplateView',
  data () {
    return {
      nameValidate: ['name', { rules: [{ required: true, message: '模板名称不能为空！' }] }],
      remarkValidate: ['remark', { rules: [{ required: false }] }],
      form: null,
      visible: '',
      showEditModal: false,
      typeEditData: {},
      columns: [
        {
          title: '类别',
          dataIndex: 'name',
          width: '30%'
        },
        {
          title: '状态',
          dataIndex: 'type',
          scopedSlots: { customRender: 'type' }
        },
        {
          title: '操作',
          key: 'operation',
          fixed: 'right',
          width: 300,
          scopedSlots: { customRender: 'action' }
        }
      ],
      tableData: [],
      key: 0,
      editData: {},
      parentId: 0,
      temporaryData: null,
      status: ''
    }
  },
  props: {
    type: String,
    id: {
      type: Number,
      default: null
    }
  },
  filters: {
    type (value) {
      if (value === 'SUCCESS') {
        return '成功'
      } else if (value === 'FAIL') {
        return '失败'
      } else {
        return ''
      }
    }
  },
  components: {
    TypeTemplateComponent
  },
  watch: {
    // editData: function (newVal, oldVal) {
    //   this.createForm()
    //   this.newEditData = newVal
    //   this.nameValidate[1].initialValue = this.newEditData.phone
    //   // 未传递edit对象，则为新增
    //   if (Object.keys(this.newEditData).length === 0) {
    //     this.editModalTitle = '新增权限'
    //     this.disabledNameInput = false
    //   } else {
    //     this.editModalTitle = '编辑权限'
    //     this.disabledNameInput = false
    //   }
    // }
  },
  beforeCreate () {
    if (this.form === null) {
      this.form = this.$form.createForm(this)
    }
  },
  created () {
    this.createForm()
    if (this.id !== null) {
      this.templateDetails()
    }
    if (this.type === '查看') {
      this.columns[2] = {}
    } else {
      this.columns[2] = {
        title: '操作',
        key: 'operation',
        fixed: 'right',
        width: 300,
        scopedSlots: { customRender: 'action' }
      }
    }
    // if(this.editData){
    //   this.type = ''
    // }
    // 未传递edit对象，则为新增
    // if (Object.keys(this.editData).length === 0) {
    //   this.editModalTitle = '新增外显号码'
    //   this.disabledNameInput = false
    // } else {
    //   this.editModalTitle = '编辑外显号码'
    //   this.disabledNameInput = true
    // }
  },
  methods: {
    createForm () {
      if (this.form === null) {
        this.form = this.$form.createForm(this)
      }
    },
    editHandleCancel () {
      this.form.resetFields()
      this.$emit('cancel')
    },
    editPermissionSubmit (values) {
      if (this.newEditData && this.newEditData.id) {
        values.id = this.newEditData.id
      }
      return addTemplate(values)
    },
    templateDetails () {
      this.confirmLoading = true
      templateDetails({ id: this.id }).then(res => {
        if (res.status !== 200) {
          this.$notification.error({
            message: '提示',
            description: res.message
          })
        }
        const data = res.body
        this.form.setFieldsValue({
          name: data.name,
          remark: data.remark
        })
        this.tableData = data.fields
        this.key = 1
      }).catch((e) => {
        this.$notification.error({
          message: '提示',
          description: e.message
        })
      }).finally(() => {
        this.confirmLoading = false
      })
    },
    editHandleOk () {
      this.form.validateFields((err, values) => {
        if (err) {
          return
        }
        if (this.tableData.length === 0) {
          this.$notification.error({
            message: '提示',
            description: '模板内容不能为空',
            key: 1
          })
          return
        }
        this.confirmLoading = true
        for (const i of this.tableData) {
          if (i.childFiled === undefined || i.childFiled.length === 0) {
            delete i.childFiled
          }
        }
        const data = { field: this.tableData, ...values, id: this.id }
        this.editPermissionSubmit(data).then(res => {
          if (res.status !== 200) {
            this.$notification.error({
              message: '提示',
              description: res.message
            })
            return
          }
          this.$emit('cut', 'ListTemplateView')
          this.$notification.success({
            message: '提示',
            description: res.message
          })
          this.form.resetFields()
        }).catch((e) => {
          this.$notification.error({
            message: '提示',
            description: e.message
          })
        }).finally(() => {
          this.confirmLoading = false
        })
      })
    },
    addClick () {
      this.showEditModal = true
      this.temporaryData = null
      this.parentId = 0
      this.status = 'add'
      this.editData = { }
    },
    addChildClick (text) {
      this.showEditModal = true
      this.temporaryData = text
      this.parentId = text.id
      this.status = 'addChild'
      this.editData = { }
    },
    editClick (text) {
      this.showEditModal = true
      this.editData = text
      this.parentId = text.parentId
      this.temporaryData = text
      this.status = 'edit'
    },
    delOk (text) {
      const dataSource = [...this.tableData]
      this.tableData = dataSource.filter((item) => {
        if (text.parentId === 0) {
          return item !== text
        } else {
          item.childFiled = item.childFiled.filter((child) => {
            return child !== text
          })
          return true
        }
      })
    },
    cancelHandle () {
      this.showEditModal = false
      this.typeEditData = {}
    },
    editHandle (data) {
      this.showEditModal = false
      fieldAdd(data).then(res => {
        if (res.status !== 200) {
          this.$notification.error({
            message: '提示',
            description: res.message
          })
          return
        }
        data.id = res.body
        if (this.status === 'add') {
          this.tableData.push(data)
        } else if (this.status === 'addChild') {
          if (this.temporaryData.childFiled === undefined) {
            this.temporaryData.childFiled = []
          }
          this.temporaryData.childFiled.push(data)
          this.tableData.push()
        } else if (this.status === 'edit') {
          this.temporaryData.name = data.name
          this.temporaryData.id = data.id
          this.tableData.push()
        }
      })
    },
    back () {
      this.$emit('cut', 'ListTemplateView')
    }
  }
}
</script>

<style scoped>
.title{
  font-size: 30px;
  text-align: center;
}
  .footer{
    text-align: center;
  }
  .footer button{
    margin: 0 30px;
  }
</style>
